<template>
  <div>
    <!-- 头部 -->
    <Header></Header>
    <div class="BoxHeight bbox positionr">
      <!-- 收藏 -->
      <div class="likeTool textr pr20">
        <i
          v-if="status == false"
          class="el-icon-star-off f22 text999"
          @click="addLike"
        ></i>
        <i
          v-if="status != false"
          class="el-icon-star-on f26 textblue"
          @click="delLike"
        ></i>
      </div>
      <div class="previewArea" v-if="'1' == isFile" v-loading="loading">
        <iframe
          :src="url"
          width="100%"
          height="100%"
          frameborder="0"
          ref="stfIframe"
        >
        </iframe>
        <div class="content"></div>
      </div>
      <div class="textc previewArea" v-if="'0' == isFile">
        <video
          :src="url"
          controls="controls"
          controlslist="nodownload"
          width="100%"
          height="300px"
        ></video>
      </div>

    </div>
    <!-- 页脚 -->
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../components/commonHead.vue";
import Footer from "../components/footer.vue";
export default {
  components: { Header, Footer },
  data() {
    return {
      url: "",
      isFile: "1",
      typeCode: "",
      typeName: "",
      machineType: "",
      timer: null,
      viewCode: "",
      status: false,
      content: "",
      loading: true,
    };
  },
  methods: {
    //   收藏
    addLike() {
      var sendData = {};
      sendData.memberCode = localStorage.getItem("memberCode");
      sendData.typeCode = this.typeCode;
      sendData.typeName=this.typeName
      sendData.machineType = this.machineType;
      var that = this;
      this.$http
        .post("memberCollect/addMemberCollect", sendData)
        .then(function (res) {
          if (200 == res.data.code) {
            that.status = true;
          } else {
          }
        });
    },
    // 取消收藏
    delLike() {
      var sendData = {};
      sendData.memberCode = localStorage.getItem("memberCode");
      sendData.typeCode = this.typeCode;
      var that = this;
      this.$http
        .post("memberCollect/deleteMemberCollect", sendData)
        .then(function (res) {
          if (200 == res.data.code) {
            that.status = false;
          } else {
          }
        });
    },
    countTime() {
      var sendData = {};
      sendData.typeCode = this.typeCode;
      sendData.typeName = this.typeName;
      sendData.viewCode = this.viewCode;
      sendData.machineType = this.machineType;
      sendData.memberCode = localStorage.getItem("memberCode");
      var that = this;
      this.$http
        .post("memberViews/saveViewInfo", sendData)
        .then(function (res) {
          if (200 == res.data.code) {
            that.viewCode = res.data.data;
          }
        });
    },
    queryCollect() {
      var sendData = {};
      sendData.memberCode = localStorage.getItem("memberCode");
      sendData.typeCode = this.typeCode;
      var that = this;
      this.$http
        .post("memberCollect/queryIsCollect", sendData)
        .then(function (res) {
          if (200 == res.data.code) {
            if ("1" == res.data.data) {
              that.status = true;
            }
          }
        });
    },
    openFile() {
      var headUrl = "https://view.officeapps.live.com/op/view.aspx?src=";
      var tempUrl = this.$route.query.url;
      this.content = this.$route.query.content;
      this.typeCode = this.$route.query.typeCode;
      this.typeName = this.$route.query.typeName;
      this.machineType = this.$route.query.machineType;
      var that = this;

      if (tempUrl.indexOf(".pdf") != -1) {
        // that.url = tempUrl;
        console.log(tempUrl);
        that.url = "/static/pdf/web/viewer.html?file=" + tempUrl;
        // debugger
      }
      if (
        tempUrl.indexOf(".xls") != -1 ||
        tempUrl.indexOf(".xlsx") != -1 ||
        tempUrl.indexOf(".docx") != -1 ||
        tempUrl.indexOf(".pptx") != -1 ||
        tempUrl.indexOf(".ppt") != -1
      ) {
        that.url = headUrl + tempUrl;
      }
      if (tempUrl.search(".mp4") != -1 || tempUrl.search(".mp3") != -1) {
        that.isFile = "0";
        that.url = tempUrl;
      }
    },
    stateChange() {
      this.loading = false;
    },
  },
  created() {
    this.openFile();
    this.queryCollect();
    clearInterval(this.timer);
    this.countTime();
    this.countDownTimer = setInterval(this.countTime, 60 * 1000);
    this.$nextTick(() => {
      // 禁用右键
      document.oncontextmenu = new Function("event.returnValue=false");
      // 禁用选择
      document.onselectstart = new Function("event.returnValue=false");
    });
    
  },
  mounted() {
    const iframe = this.$refs.stfIframe;
    const that = this;
    iframe.onload = function () {
      that.stateChange();
    };
  },
};
</script>

<style  scoped>
.previewArea {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: calc(100vh - 46px);
  width: 100vw;
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  font-size: 16px;
}
.likeTool {
  height: 46px;
  line-height: 52px;
}
</style>